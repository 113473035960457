@import '/src/theme/colors', '/src/theme/variables';

.uel-semidonut-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  background-color: $color-white;
  
  .uel-underline-text-button {
    font-family: $font-family-primary-semibold;
    font-size: 12px;
    line-height: 14px;
  }
  .is-disabled {
    text-align: center;
    margin-top: 15px;
    p {
      color: $color-secondary;
      font-family: $font-family-secondary-medium;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }
  .semidonut-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    svg {
      transform: scale(.8);
      g > path {
        opacity: 0.5;
      }
    }
    .points {
      position: absolute;
      bottom: 5px;
      color: $color-secondary;
      font-family: $font-family-secondary-semibold;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      &.r1 {
        color: $color-chart-risk-l1;
      }
      &.r2 {
        color: $color-chart-risk-l2;
      }
      &.r3 {
        color: $color-chart-risk-l3;
      }
      &.r4 {
        color: $color-chart-risk-l4;
      }
    }
    .focusedRiskLevel {
      opacity: 1;
      stroke-width: 2px;
    }
    &.r-1 g > path {
      fill: $color-gray;
      stroke-width: 0;
    }
    &.r1 g:nth-child(1) > path {
      @extend .focusedRiskLevel;
      stroke: $color-chart-risk-l1;
    }
    &.r2 {
      g:nth-child(1) > path {
        opacity: 1;
      }
      g:nth-child(2) > path {
        @extend .focusedRiskLevel;
        stroke: $color-chart-risk-l2;
      }
    }
    &.r3 {
      g:nth-child(1) > path, g:nth-child(2) > path {
        opacity: 1;
      }
      g:nth-child(3) > path {
        @extend .focusedRiskLevel;
        stroke: $color-chart-risk-l3;
      }
    }
    &.r4 {
      g:not(:last-child) > path {
        opacity: 1;
      }
      g:nth-child(4) > path {
        @extend .focusedRiskLevel;
        stroke: $color-chart-risk-l4;
      }
    }
  }
  .range {
    display: inline-block;
    min-width: 230px;
    color: $color-secondary;
    font-family: $font-family-secondary-regular;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 20px;
  }
}