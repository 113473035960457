/* Primary */
$color-primary: #454ff5;
$color-secondary: #1d335c;
$color-tertiary: #6e758c;

/* Gray scale  */
$color-gray: #b8b8b8;
$color-gray-lighter: #eeeeee;
$color-gray-dark: #707070;
$color-gray-medium: #656565;
$color-gray-dark-2: #7E7E7E;
$color-gray-light: #dde1e4;
$color-gray-light-2: #E4E4E4;
$color-gray-strong: #979797;
$color-gray-medium: #b5b5b5;

$color-white: #ffffff;
$color-gray-opcity: #4c566bb2;
$color-gray-opacity-ligth:#666666;

/* Purples */
$color-purple: #472ea3;
$color-purple-strong: #5c65f7;
$color-purple-light: #7648e8;
$color-purple-light-2: #a88de8;
$color-purple-light-3: #ece3ff;
$color-purple-shadow-box: #bdc1fa;
$color-purple-warning: #454ff5;
/* Others */
$color-yellow: #fccb5c;
$color-yellow-light: #ffe4a7;
$color-cyan: #68ede9;
$color-blue-purple: #000bc3;
$color-blue-light: #525CFD;
$color-gray-dark-shadow: #70707030;
$color-secondary-alt: #1e335c;
$color-red: #da5274;
$color-green: #78f97c;
$color-chart-risk-l1: #D76A66;
$color-chart-risk-l2: #E1A64D;
$color-chart-risk-l3: #F2D163;
$color-chart-risk-l4: #6CCB87;
$color-profile-image-bg: #94A6CB;