.message-part-pl{
    display: flex;
    flex-direction: column;  // Add this line
    justify-content: center;
    align-items: center;
    text-align: center;
    position:relative;
}


.button-container-pl {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding-top: 15px;
    // padding-bottom: 15px;
}

.terms-container-pl {
    align-items: center;
}

.terms-container-pl,
.button-container-pl {
    width: 65%; // adjust as needed
    margin: 0 auto;
}


.home-container-pl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #1e335c; 
}

.terms-and-button-container {
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 20px;

    .mantine-1dgn3jq {
        border: 2px solid #ced4da;
    }
  }

.header-pl {
    color: #454FF5;
}

.plan-details {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0px;
    padding-top: 10px;
  
    .plan-terms, .plan-values {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  
      .term-item, .value-item {
        min-height: 56px; 
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

.link-button-plan {
    text-align: left;
    margin-bottom: 10px; // adjust the value as per your requirements
    align-self: flex-start;
  }

.plan-price {
    font-size: 60px;
    font-family: "Jakarta-bold";
    // color: #454FF5;
}

.plan-price-hd {
    padding-bottom: 15px;
}


.plan-terms {
    text-align: left; 
    padding-right: 30px;
}

.term-item p {
    display: inline-block;
    vertical-align: middle;
}

.plan-slogan {
    padding-bottom: 10px;
}

.terms-container-pl .uel-checkbox-component .uel-label {
    font-size: 9px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.uel-button {
    padding: 0 10px;
    
}

.home-container-pl .custom-back-button-container .UelBackButton {
    margin: 0 !important;
  }

.link-button-plan-quit {
    text-align: center;
    margin-bottom: 10px; // adjust the value as per your requirements
    // align-self: flex-start;
    padding-top: 0px;
    padding-bottom: 50px;
    align-items: center;
  }

.custom-slide-plan {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    justify-content: center;
    /*padding-bottom: 150px;*/

    p {
        font-size: inherit;
        // margin-bottom: 20px;
        font-family: sans-serif; 
    }
}

@media (max-width: 768px) { /* or whatever breakpoint you choose */
    .custom-slide-plan {
        align-items: center;/* styles to center the slides on mobile */
    }

    .terms-and-button-container {
        width: 80%;
    }

    .plan-values {
        text-align: right; 
        padding-bottom: 10px; 
    
        p {
            margin-bottom: 15%; 
            font-size: 15px;
        }
    }
    .plan-slogan {
        padding-bottom: 10px;
        font-size: 17px !important;
    }
 }

.cb-plan-carousel {
    flex: 1;
    align-items: center;
    width: 55%;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

@media (min-width: 768px) { /* or whatever breakpoint you choose */
    .cb-plan-carousel {
        width: 70%;
    }

    .header-pl {
        color: #454FF5;
        font-size: 45px;
        padding-bottom: 15px;
    }

    .plan-slogan {
        font-size: 30px;
    }

    .home-layout .home-layout-content .content .plan-slogan {
        text-align: center;
        font-size: 22px;
    }

    .home-layout .home-layout-content .content .header-pl {
        text-align: center;
    }

    .home-layout .home-layout-content .content .plan-price-hd {
        text-align: center;
    }


    .plan-values {
        text-align: right;
        padding-bottom: 10px; 
        p {
            margin-bottom: 8%; 
            font-size: 15px;
        }
    }

 }

 @media only screen and (min-width: 0px) and (max-width: 350px) {
    .custom-slide-plan {
        align-items: center;
    }

    .terms-and-button-container {
        width: 60%;
    }

    .cb-plan-carousel {
        flex: 1;
        align-items: center;
        width: 40%;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .plan-values {
        text-align: right; 
        padding-bottom: 10px; 
        p {
            margin-bottom: 30px; 
            font-size: 15px;
        }
    }
}