@import "../../../theme/colors", "../../../theme/variables";

.container-transaction-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 226px);
  padding: 22px 0 $navbar-height;
  position: relative;

  & > *:not(.uel-loader) {
    width: 100%;
  }

  .uel-loader {
    margin: auto;
    transform: translateY(-5vh);
  }

  h2 {
    margin-top: 50px;
    color: $color-secondary;
  }

  .uel-dropdown.alter {
    margin-bottom: 22px;
  }

  .balance-wrapper {
    display: flex;
    flex-direction: column;

    .uel-loader {
      display: none;
    }
  }

  .component-category-tip {
    margin-top: 24px;
  }

  .figures-container {
    span {
      color: $color-secondary;
    }
  }

  .transactions-wrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 628px);
    margin-top: 30px;
    position: relative;

    &.tall {
      min-height: calc(100vh - 432px);
    }

    .uel-loader {
      transform: translateY(-5vh);
    }
  }

  .component-gps-transactions-list {
    h1 {
      margin-top: 0;
    }
  }

  h3 {
    font-family: $font-family-secondary-semibold;
    color: $color-secondary;
    text-align: center;
  }

  @include tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "filters data"
      "balance data"
      ". data";
    grid-template-rows: auto;
    align-content: start;
    align-items: start;
    column-gap: 37px;
    min-height: 486.5px;
    padding: 36px 0 0;
    position: relative;

    h2 {
      margin-top: 0;
      position: absolute;
      top: 37px;
      left: 0;
    }

    & > .uel-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - 37.5px));
    }

    .uel-dropdown.alter {
      margin: -3px 0 28px;
    }

    .balance-wrapper {
      grid-area: balance;
      display: flex;
      flex-direction: column;
      height: 195px;

      .uel-loader {
        display: flex;
        transform: none;
      }

      .component-category-tip {
        margin-top: 36px;
      }
    }

    .transactions-wrapper {
      grid-area: data;
      min-height: 450.5px;
      overflow: scroll;
      margin-top: 0;

      &.tall {
        min-height: 450.5px;
      }

      .uel-loader {
        transform: translateY(-94px);
      }

      h3 {
        position: absolute;
        top: 26%;
      }

      .component-gps-transactions-list {
        max-width: calc(50vw - 70px);
        padding-bottom: 0;

        h1 {
          margin-top: 0 !important;
        }
      }
    }
  }
}
