.component-gps-category-label{
  display:grid;
  place-items: center;
  min-width: 42px;
  max-width: 42px;
  height: 42px;
  margin:auto;
  border-radius: 50%;
  
  img{
    object-fit: contain;
  }
}