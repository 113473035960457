@import '/src/theme/index.scss', "/src/theme/fontface";

.uel-dropdown {
  margin-bottom: 20px;
  position: relative;


  
  .mantine-Select-label {
    margin-bottom: 7px;
  }
  
  .mantine-Select-error, .mantine-Select-label	{
    margin-left: 3px;
  }
  
  .mantine-Select-input {
    border-width: 2px;
    border-radius: 7px;
    height: 50px;
    padding-left: 20px;
    border-color: $color-gray-light;
    &:focus {
      border-color: $color-gray-light;
    }
  }
  
  .mantine-Select-rightSection {
    cursor: pointer;
    .dropdown-icon {
      display: flex;
      align-items: center;
      img {
        position: absolute;
        right: 20px;
        height: 8px;
        width: 10px;
      }
    }
  }
  
  .dropdown-font { 
    font-size: 15px;
    line-height: 14px;
    font-family: $font-family-secondary-medium;
  }
  
  .mantine-Select-input, .mantine-Select-label {
    @extend .dropdown-font;
    color: $color-tertiary;
  }
  
  .mantine-ittua2{
    margin-left: 10px;
  }
  
  .mantine-Select-icon {
    color: $color-primary;
    background-color: $color-primary;
  }
  
  .mantine-Select-itemsWrapper > * {
    background-color: transparent !important;
    color: $color-tertiary !important;
    padding-top: 15px;
    padding-bottom: 15px;
    @extend .dropdown-font;
    &:hover {
      background-color: $color-gray-lighter !important;
    }
  }
  
  &.alter {
    .mantine-Select-label {
      font-family:$font-family-secondary-semibold;
      font-size:10px;
      color: $color-secondary;
      line-height:18px;
    }
    .mantine-Select-input { 
      font-family: $font-family-secondary-semibold;
      font-size: 12px;
      line-height: 18px;
      color: $color-gray-dark;
      border-radius: 25px;
      text-align: center;
      height: 37px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-color: transparent;
      &:focus {
        border-color: transparent;
      }
    }
    .mantine-Select-itemsWrapper > * {
      font-family: $font-family-secondary-semibold;
      color: $color-gray-dark !important;
      font-size: 12px;
    }
    .mantine-Select-rightSection {
      .dropdown-icon {
        display: flex;
        align-items: center;
        img {
          height: 8px;
          width: 15px;
        }
      }
    }
  }

  @include tablet {

    &.alter{
      .mantine-Select-label {
        font-size:12px;
      }
    }
  }
}
