@import '/src/theme/colors', '/src/theme/variables';

.component-category-tip {
    padding: 10px 42px;
    background-color: $color-white;
    box-shadow: 0px 0px 20px $color-yellow;
    font-family: $font-family-secondary-medium;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border-radius: 15px;
    color: $color-secondary;
}