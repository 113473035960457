@import "/src/theme/index.scss", "/src/theme/variables";

.container-profile {
  display: flex;
  flex-direction: column;

  .container-profile-information {
    display: flex;
    width: 100%;
    flex-direction: column;
    .container-name-avatar {
      display: flex;
      margin-top: 25px;
      margin-bottom: 45px;
      gap: 15px;
      .mantine-Avatar-root {
        width: 64px;
        height: 64px;
        .mantine-Avatar-placeholder {
          background-color: $color-purple-light-2;
          font-family: $font-family-primary-bold;
          color: $color-white;
          font-size: 20px;
          line-height: 39px;
        }
      }
      .name-txt {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: $font-family-primary-bold;
          color: $color-secondary-alt;
          font-size: 20px;
          line-height: 39px;
        }
      }
    }
    .each-info {
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      span:nth-child(1) {
        font-family: $font-family-secondary-medium;
        color: $color-tertiary;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 12px;
      }
      span:nth-child(2) {
        font-family: $font-family-secondary-medium;
        color: $color-tertiary;
        font-size: 16px;
        line-height: 14px;
        margin-bottom: 10px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: $color-gray-light-2;
    margin-top: 15px;
  }
  .container-session {
    display: flex;
    margin-top: 35px;
  }

  @include tablet {
    height: calc(100vh - var(--header-height));
  }
}
