@import '/src/theme/colors';

.uel-loader {
  margin:auto;
  color: $color-primary;
  &.primary {
    color: $color-primary;
  }
  &.secondary {
    color: $color-secondary;
  }
  &.tertiary {
    color: $color-tertiary;
  }
}
