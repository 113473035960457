@import "/src/theme/index.scss", "/src/theme/variables";

.add-bank-account-after-skip {
  p {
    font-size: 15px;
    color: $color-tertiary;
    font-family: $font-family-secondary-semibold;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  .loading-container {
    flex-direction: column;
    .loading-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10vh;
    }
  }
}