@import "/src/theme/index.scss", "/src/theme/variables", "/src/theme/colors";

.finerio-component-container {
  width: 100%;
  height: 100%;
  min-height: 407px;
  position: relative;

  .infinite-loading,
  .finerio-component {
    height: 100%;
    width: 100%;
    position: absolute;
    //overflow-y: scroll;
  }

  .infinite-loading {
    display: flex;
    background-color: transparent;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0px 30px;
    .message {
      margin-top: 400px;
      font-size: 13px;
      color: $color-secondary;
    }
    .link {
      margin-top: 12px;
      font-size: 13px;
      color: $color-primary;
      font-weight: $bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  // @include tablet {
  //   height: calc(100vh - 370px);
  //   .wizard-container {
  //     position: relative;
  //     top: -190px;
  //     overflow-y: hidden;
  //   }
  //   .infinite-loading,
  //   .finerio-component {
  //     overflow-y: hidden;
  //   }
  // }

  // @include landscape{
  //   .wizard-container{
  //     top:-55px;
  //   }

  //   .infinite-loading,
  //   .finerio-component {
  //     overflow-y: auto;
  //   }

  // }
}

