@import '/src/theme/index.scss';

.credit-warning-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $color-purple-warning;
  margin: 12px 0px 18px 0px;
  border-radius: 15px;
  padding: 10px 30px 10px 14px;
  cursor: pointer;
  .information-icon {
    margin-right: 15px;
  }

  .credit-warning-text {
    color: $color-white;
    font-family: $font-family-secondary-medium;
    font-size: 12px;
    line-height: 15px;
  }


}