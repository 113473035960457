@import '/src/theme/colors', '/src/theme/variables';

.uel-underline-text-button {
  border: none;
  background-color: transparent;
  outline: none !important;
  font-size: 12px;
  font-family: $font-family-secondary-semibold;//i feel work its more similar to poppins than jakarta
  text-decoration: underline;
  &.primary {
    color: $color-primary;
  }
  &.secondary {
    color: $color-secondary;
  }
  &.tertiary {
    color: $color-tertiary;
  }
}
