@import "/src/theme/colors", "/src/theme/variables";

.uel-button {
  width: 100%;
  min-height: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-family: $font-family-secondary-semibold;
  line-height: 14px;

  &.secondary {
    background-color: $color-primary;
    color: $color-white;
    .uel-loader {
      stroke: $color-white;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &.primary {
    @extend .secondary;
    &:disabled {
      background-color: $color-gray-dark;
    }
  }
  &.tertiary {
    background-color: $color-white;
    color: $color-primary;
    &:disabled {
      opacity: 0.5;
    }
  }
  &.quaternary {
    @extend .tertiary;
    &:disabled {
      color: $color-white;
      opacity: 1;
      background-color: $color-gray-medium;
    }
  }
  &.quinary {
    @extend .tertiary;
    border: 2px solid $color-primary;
  }

  @include tablet {
    width: var(--submitWidthTablet);
    margin-left: auto;
    margin-right: auto;
  }
}
