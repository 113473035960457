@import "/src/theme/index.scss";

.home-layout .home-layout-content .content {
  padding-bottom: 0px;
}

.form-kuspit-info {
  width: 100%;
  padding-bottom: 80px;

  .title-text {
    color: $color-primary;
    text-align: center;
    font-size: 30px;
    font-family: $font-family-primary-bold;
    line-height: 49px;
  }

  .subtitle-text {
    margin-top: 6px;
    color: $color-tertiary;
    text-align: center;
    font-size: 15px;
    font-family: $font-family-secondary-medium;
    line-height: 18px;
  }


  @include tablet {
    padding-bottom: 0;
    .subtitle-text {
      max-width: 513px;
      margin: 24px auto 0;
    }
    .form-adress-container {
      width: 350px;
      margin-left: auto;
      margin-right: auto;
    }

    .form-container {
      .btn-container {
        margin-top: 86px;
        padding-bottom: 0px;
      }

      div:not(.btn-container) {
        .text-input-component {
          .mantine-InputWrapper-root {
            width: auto;

            .mantine-Input-wrapper {
              width: auto;
            }
          }
        }
      }
    }
  }
}
.form-container {

    .numbers-containers {
      display: flex;
      flex-direction: row;
      gap: 10px;
  
      .text-input-component:nth-of-type(2){
        .mantine-Input-wrapper{
          margin-top:1px;
        }
      }
    }

    .checkbox-container{
      margin-right: 15px;
    }
    .error-text{
      color: $color-yellow;
      font-size: 10px;
      margin-left: 10px;
      font-family: $font-family-secondary-medium;
    }
  
    .btn-container {
      margin-top: 43px;
    }
  
    @include tablet{
      .numbers-containers{
        .text-input-component:nth-of-type(2){
          margin-top: 1.5px;
        }
      }
    }
  }

  .home-layout .home-layout-content .datos-personales-cb-head {
  color: #454FF5;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
}

.datos-personales-cb-head3 {
  color:#1d335c;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.link-button-kuspit-info {
  text-align: left;
  margin-bottom: 10px; // adjust the value as per your requirements
  align-self: flex-start;
}

.link-button-kuspit-quit {
    text-align: center;
    margin-bottom: 10px;
    align-self: flex-start;
    padding-top: 15px;
    padding-bottom: 10%;
}

Select-label {
  color: #1d335c;
}

.text-input-component .mantine-TextInput-label {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #1d335c;
  font-size: 15px;
  font-family: "Work-medium";
  line-height: 14px;
}

.uel-dropdown .mantine-Select-input, .uel-dropdown .mantine-Select-label {
  color: #1d335c;
  border-left-color: #454FF5;
}

.text-input-component .mantine-TextInput-input {
  border: 2px solid #dde1e4;
  color: #1d335c;
  font-size: 14px;
  height: 50px;
  padding-left: 18px;
  border-radius: 7px;
  font-family: "Work-medium";
  border-left-color: #454FF5;
}