@import "/src/theme/colors", "/src/theme/variables";

.success-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  // height: calc(80vh - var(--header-height));
  min-height:150px;
  .icon-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: 100px;
    img {
      height: 133px;
      width: 133px;
      transform: translateY(-5vh);
    }
  }

  @include tablet {
    height: calc(80vh - var(--header-height) - 496px);
    .icon-wrapper {
      align-self: flex-start;
    }
    .cb-success-header h2{
      color: #454FF5;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
}

.home-layout .home-layout-content .cb-success-header {
  color: #454FF5;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
  font-size: 20px;
}
