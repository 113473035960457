@import "/src/theme/colors", "/src/theme/variables";

.uel-gps-button {
  color: $color-secondary-alt !important;
  border-radius: 35px;
  box-shadow: $shadow-around;
  font-family: $font-family-primary-bold;
  font-size: 20px;
  flex-wrap: wrap;
  position: relative;
  align-content: center;
  .hidden-wrapper {
    display: none;
    padding: 12px 5px 5px 5px;
    position: absolute;
    top: calc(100%);
    left: -5px;
    width: calc(100% + 10px);
    z-index: 1;
    &:hover {
      display: block;
    }
  }
  .icon {
    position: absolute;
    right: 45px;
  }
  .label {
    display:none;
    padding-top: 10px;
    width: 80%;
    color: $color-secondary;
    line-height: 16px;
    font-family: $font-family-secondary-regular;
    font-size: 14px;
  }
  .dropdown-arrow {
    display:none;
  }

  @include tablet {
    justify-content: flex-start;
    padding-left: 25px;
    width: 100%;
    .label{
      display:inline-block;
    }
    .icon {
      right: 20px;
    }
    &.dropdown-behavior {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      border-radius: 18px;
      &:not(.disabled):hover,
      &:not(.disabled):focus {
        background-color: $color-primary;
        &,
        .label {
          color: $color-white !important;
        }
        .dropdown-arrow {
          display:inline;
          position: absolute;
          right: 15px;
        }
        .hidden-wrapper {
          display: block;
        }
      }
      .items-wrapper {
        border-radius: 5px;
        box-shadow: $shadow-around;
        margin: 2px;
        background-color: $color-white;
        color: $color-secondary-alt;
        line-height: 24px;
        font-family: $font-family-secondary-regular;
        overflow: hidden;
        .item {
          font-size: 21px;
          padding: 12px 0;
          text-align: center;
          &:not(:last-child) {
            border-bottom: 1px solid $color-blue-light;
          }
          &:hover {
            background-color: $color-blue-light;
          }
        }
      }
    }
  }
  @include desktop {
    .label{
      display:inline-block;
      width: 100%;
    }
  }
}

.button-container-kyc {
  padding-top: 30px;
  width: 50%;
}

.header-description-kyc {
  color:#6E758C;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 20px;
}

.list-kyc-details {
  list-style-type:disc;
  padding-left: 20px;
  font-family: "Jakarta-semibold";
  font-size: 14px;

}

.list-container {
  background: #f9f9f9; /* change color as needed */
  border-radius: 10px; /* adjust for desired roundness */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* adjust for desired shadow */
  padding: 28px; /* adjust for desired spacing */
  text-align: left;
}

.list-container li {
  padding: 10px 0; /* Change values as needed */
  list-style-type: none;
}

.list-kyc-details li::before {
  content: "\2713";
  margin-right: 5px;
  
}

.home-layout .home-layout-content .header-kyc {
  color:#454FF5 ;
  font-size: 25px;
  padding-bottom: 20px;
}


.link-button-personal {
  text-align: left;
  margin-bottom: 10px; // adjust the value as per your requirements
  align-self: flex-start;
}

.link-button-kyc {
  padding-top: 15px;
  padding-bottom: 30%;
}