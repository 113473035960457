@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin landscape {
  @media (orientation:landscape){
    @content
  }
}

