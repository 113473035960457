@import '/src/theme/colors', '/src/theme/variables';

.credit-score-card {
  box-shadow: $shadow-around;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 60px 30px;
  position: relative;
  p {
    text-align: center;
    color: $color-secondary;
    font-family: $font-family-secondary-semibold;
    font-size: 15px;
    line-height: 18px;
  }
  img {
    position :absolute;
    top: 15px;
    right: 20px;
  }
}