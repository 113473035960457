@import "/src/theme/colors", "/src/theme/variables";

.modal-finerio {
  .mantine-Modal-modal {
    width: 363px;
  }
  .header-title-seguros {
    display: flex;
    justify-content: center;
    h2 {
      font-size: 30px;
      line-height: 38px;
      font-family: $font-family-primary-bold;
      color: $color-secondary;
      margin-bottom: 20px;
    }
  }
  .description-about-seguros {
    padding: 0px 10px 0px 10px;
    p {
      font-size: 15px;
      line-height: 18px;
      font-family: $font-family-secondary-medium;
      color: $color-tertiary;
      text-align: center;
    }
  }
  .image-logo-finerio {
    display: flex;
    justify-content: center;
    margin: 10px 0px 10px 0px;
    img {
      width: 208px;
      height: 72px;
    }
  }
  .container-points-finerio {
    display: flex;
    margin: 20px 0px 20px 0px;
    .icon-check {
      display: flex;
      flex: 1;
      justify-content: center;
      img {
        width: 12px;
        height: 15px;
      }
    }
    .content-text {
      flex: 5;
      display: flex;
      flex-direction: column;
      .title-points {
        display: flex;
        margin-bottom: 5px;
        span {
          font-size: 15px;
          line-height: 18px;
          font-family: $font-family-secondary-semibold;
        }
      }
      .description-points {
        display: flex;
        p {
          font-size: 15px;
          line-height: 18px;
          font-family: $font-family-secondary-medium;
          color: $color-tertiary;
        }
        .uel-underline-text-button.primary {
          font-size: 15px;
          font-family: $font-family-secondary-medium;
        }
      }
    }
  }

  @include tablet {
    .mantine-Modal-modal {
      width: 470px;
    }
    .description-about-seguros {
      p {
        margin-bottom: 50px;
      }
    }
    .image-logo-finerio {
      margin-bottom: 50px !important;
    }
  }
}
