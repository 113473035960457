@import "/src/theme/index.scss";

.form-kuspit-info {
  width: 100%;
  padding-bottom: 80px;

  .title-text {
    color: $color-primary;
    text-align: center;
    font-size: 30px;
    font-family: $font-family-primary-bold;
    line-height: 49px;
  }

  .subtitle-text {
    margin-top: 6px;
    color: $color-tertiary;
    text-align: center;
    font-size: 15px;
    font-family: $font-family-secondary-medium;
    line-height: 18px;
  }

  .link-button-personal {
    text-align: left;
    margin-bottom: 10px; // adjust the value as per your requirements
    align-self: flex-start;
  }


  @include tablet {
    padding-bottom: 0;
    .subtitle-text {
      max-width: 513px;
      margin: 24px auto 0;
    }
    .form-adress-container {
      width: 350px;
      margin-left: auto;
      margin-right: auto;
    }

    .form-container {
      .btn-container {
        margin-top: 86px;
        padding-bottom: 80px;
      }

      div:not(.btn-container) {
        .text-input-component {
          .mantine-InputWrapper-root {
            width: auto;

            .mantine-Input-wrapper {
              width: auto;
            }
          }
        }
      }
    }
  }
}
.form-container {

    .numbers-containers {
      display: flex;
      flex-direction: row;
      gap: 10px;
  
      .text-input-component:nth-of-type(2){
        .mantine-Input-wrapper{
          margin-top:1px;
        }
      }
    }

    .text-input-component .mantine-TextInput-input:disabled {
      color: #6E758C !important;
      opacity: 1 !important;
  }

    .checkbox-container{
      margin-right: 15px;
    }
    .error-text{
      color: $color-yellow;
      font-size: 10px;
      margin-left: 10px;
      font-family: $font-family-secondary-medium;
    }
  
    .btn-container {
      margin-top: 43px;
      // padding-bottom: 100px;
    }
  
    @include tablet{
      .numbers-containers{
        .text-input-component:nth-of-type(2){
          margin-top: 1.5px;
        }
      }
    }
  }

  .home-layout .home-layout-content .datos-personales-cb-head {
  color: #454FF5;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
}

.datos-personales-cb-head3 {
  color:#6E758C;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.link-button-personal-quit {
  text-align: center;
  margin-bottom: 10px; // adjust the value as per your requirements
  align-self: flex-start;
  padding-top: 15px;
  padding-bottom: 80px;
}

