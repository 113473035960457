@import '/src/theme/colors', '/src/theme/variables';

.component-gps-category {
  display: flex;
  align-items: center;
  
  .content-wrapper {
    display: flex;
    flex-direction: column;
    row-gap:2px;
    width:calc(100% - 40px);
    color: $color-secondary;
    padding-left: 10px;
    justify-content: center;

    *{
      font-size:10px;
    }

    .label {
      width:100%;
      font-family: $font-family-secondary-semibold;
      
      line-height: 1;
      overflow:hidden;
      text-overflow: ellipsis;
    }
    .amount {
      font-family: $font-family-secondary-medium;
    }
  }
}
