@import "/src/theme/colors", "/src/theme/variables";

.uel-small-modal {
  .content {
    text-align: center;
    .header {
      padding-bottom: 80px;
      h2 {
        font-size: 30px;
        line-height: 38px;
        font-family: $font-family-primary-bold;
        color: $color-secondary;
        padding-bottom: 40px;
      }
      .description {
        font-size: 15px;
        line-height: 18px;
        color: $color-tertiary;
      }
      .subtitle {
        @extend .description;
        font-family: $font-family-secondary-bold;
        margin-bottom: 20px;
      }
    }
    .uel-underline-text-button {
      font-family: $font-family-secondary-semibold;
      line-height: 14px;
      font-size: 15px;
    }
  }
  &.with-custom-bg {
    .mantine-Modal-modal {
      background-image: url("../../../../public/images/modal_bg.svg");
      background-size: cover;
      width: 632px;
    }
  }
  .mantine-Modal-modal {
    margin: 0 15px;
  }
  &.size-md {
    .header {
      padding-top: 80px;
    }
    .mantine-Modal-modal {
      padding-bottom: 30px;
    }
  }
  .button-option {
    margin-top: 15px;
  }

  @include tablet {
    .description {
      font-size: 21px !important;
    }

    //variants
    &.account-created-modal {
      .header {
        padding-bottom: 0;
        width: 400px;
        margin-left: auto;
        margin-right: auto;
      }
      h2 {
        margin-top: 50px;
      }
      .description {
        margin-bottom: 50px;
      }
    }
    &.wrong-password-modal,
    &.cant-verify-bank-account-modal,
    &.wrong-credentials-modal {
      .header {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
      }
      h2 {
        margin-top: 50px;
      }
    }
    &.wrong-password-modal {
      .header {
        padding-bottom: 150px !important;
      }
      .mantine-Modal-modal {
        width: 430px;
      }
    }
    &.cant-verify-bank-account-modal {
      .header {
        padding-bottom: 120px !important;
      }
      h2 {
        padding-bottom: 80px !important;
      }
      .mantine-Modal-modal {
        padding-bottom: 90px !important;
        width: 490px;
      }
    }
    &.wrong-credentials-modal {
      .header {
        padding-bottom: 120px !important;
      }
      .mantine-Modal-modal {
        width: 400px;
      }
    }
  }
}
