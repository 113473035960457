@import 'variables';

/* Primary Regular */
@font-face {
  font-family: $font-family-primary-regular;
  src: url('./fonts/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: $regular;
  font-display: auto;
  font-style: normal;
}

/* Primary Bold */
@font-face {
  font-family: $font-family-primary-bold;
  src: url('./fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: $bold;
  font-display: auto;
  font-style: normal;
}

/* Primary Extrabold */
@font-face {
  font-family: $font-family-primary-extrabold;
  src: url('./fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
  font-weight: $extrabold;
  font-display: auto;
  font-style: normal;
}

/* Primary Semibold */
@font-face {
  font-family: $font-family-primary-semibold;
  src: url('./fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
  font-weight: $semibold;
  font-display: auto;
  font-style: normal;
}

/* Secondary Bold */
@font-face {
  font-family: $font-family-secondary-bold;
  src: url('./fonts/WorkSans-Bold.ttf') format('truetype');
  font-weight: $bold;
  font-display: auto;
  font-style: normal;
}

/* Secondary Semibold */
@font-face {
  font-family: $font-family-secondary-semibold;
  src: url('./fonts/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: $semibold;
  font-display: auto;
  font-style: normal;
}

/* Secondary Medium */
@font-face {
  font-family: $font-family-secondary-medium;
  src: url('./fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: $medium;
  font-display: auto;
  font-style: normal;
}

/* Secondary Regular */
@font-face {
  font-family: $font-family-secondary-regular;
  src: url('./fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: $regular;
  font-display: auto;
  font-style: normal;
}