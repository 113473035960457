@import '/src/theme/index.scss';

.card-bank-account-container {
  display: flex;
  flex-direction: column;
  width: 66px;
  height: 66px;
  box-shadow: $shadow-around;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  
  .img-bank-account {
    align-self: center;
    font-size: 7px;
  }

  .text-bank-account {
    font-size: 9px;
    font-family: $font-family-secondary-semibold;
    color: $color-gray-opacity-ligth;
  }
}