@import 'variables';

h1 {
  font-size: 30px;
  font-family: $font-family-primary-bold;
}

h2 {
  font-size: 20px;
  font-family: $font-family-primary-bold;
}

h3 {
  font-size: 13px;
  font-family: $font-family-primary-semibold;
}

p {
  font-size: 10px;
  font-family: $font-family-secondary-regular;
}

span {
  font-size: 12px;
  font-family: $font-family-secondary-regular;
}