@import "../../../theme/fontface";

.component-gps-transactions-list{

  h1 {
    margin-bottom:8px;
    font-family: $font-family-secondary-semibold;
    font-size: 12px;
    width: 100%;
    text-align: left;
    color: $color-secondary;
    line-height:18px;
  }

  @include tablet{
    h1{
      margin-bottom:0 !important;
    }
    .component-gps-transaction:first-of-type{
      margin-top:7px;
    }
  }
  
}