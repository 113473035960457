.component-expenses-pie-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:5px;
}

.chart-wrapper {
  margin: auto;

  .apexcharts-inner{
    cursor:pointer;
  }
}
