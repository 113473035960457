@import '/src/theme/variables';

.screen-loading {
  display: grid;
  place-items: center;
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: calc(100% - var(--header-height));
  img {
    transform: translateY(-5vh);
  }
}