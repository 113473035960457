@import '/src/theme/colors', '/src/theme/variables';

.mantine-Dialog-closeButton{
  position: relative;
  top: -5px;
}
.container-install{
  display: flex;
  height: 100px;
  margin-top: 10px;
  .description-install{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-gray-dark;
    font-size: 18px;
    span:nth-child(1){
      font-size: 20px;
      font-family: $font-family-secondary-medium;
    }
    span{
      margin-right: 2px;
      font-size: 14px;
      font-family: $font-family-secondary-medium;
    }
  }
  .btn-install{
    flex: 2;
    margin-top: 5px;
    padding-left: 5px;
    display: flex;
    width: 12px;
    align-items: flex-start;
  }
}