@import "../../../theme/colors";

.component-gps-add-button {
  display: flex;
  width: 65px;
  height: 65px;
  background-color: $color-primary;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.109804);

  img {
    --square-fit: calc(100% - 40px);
    width: var(--square-fit);
    height: var(--square-fit);
    margin: auto;
  }
}
