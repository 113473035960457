@import '/src/theme/index.scss';

.backbutton-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-button{
    width: 8px;
    height: 12px;
  }
  .backbutton-text {
    font-size: 12px;
    line-height: 18px;
    font-family: $font-family-secondary-semibold;
    margin-left: 7px;
    color: $color-secondary;
  }
}