@import "../../../theme/fontface","../../../theme/colors";

.component-gps-transaction{
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 21px 15px 11px;
  border-top:2px solid $color-gray-medium;

  *{
    font-family: $font-family-secondary-medium;
    font-size:10px;
  }

  .label-info{
    display:flex;
    justify-content: flex-start;
    column-gap:16px;
    max-width:72.5%;

    .component-gps-category-label{
      width:40px;
      position:relative;
      top:10px;
    }
  
    .time-address-account{
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap:12px;
      min-width: 0;

      .time{
        font-family: $font-family-secondary-semibold;
      }

      .address-account{
        display:flex;
        flex-direction: column;

        p{
          line-height:1.1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .account{
          margin-top:7px;
          font-size: 8px;
          color:$color-gray-dark-2;
        }
      }
    }
  }

  .amount{
    position:relative;
    top:8px;
    font-family: $font-family-secondary-semibold;
  }

}