@import "/src/theme/fontface", "/src/theme/colors", "/src/theme/mixins.scss";

.container-timeframe-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 226px);
  padding: 22px 0 $navbar-height;
  position: relative;

  & > *:not(.uel-loader) {
    width: 100%;
  }

  .uel-loader {
    margin: auto;
    transform: translateY(-5vh);
  }

  h2 {
    margin-top: 50px;
    color: $color-secondary;
  }

  h3 {
    text-align: center;
    color: $color-secondary;
  }

  .component-gps-filters {
    .uel-dropdown {
      margin-bottom: 22px;
    }

    & + .uel-loader {
      transform: none;
    }
  }

  .balance-wrapper {
    margin-top: 5px;
    .uel-loader {
      display: none;
    }

    .balance-cards-container {
      margin: 0 0 34px;
    }
  }

  .expenses-card-wrapper {
    display: flex;
    min-height: calc(100vh - 719px);

    &.tall {
      min-height: calc(100vh - 622px);
    }

    .uel-loader {
      transform: translateY(-1vh);
    }
  }

  .button-copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin: 23px 0 12px;

    h3 {
      font-size: 13px;
      font-family: $font-family-secondary-semibold;
      color: $color-secondary;
    }
  }

  @include tablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      ". data"
      "filters data"
      "balance data"
      "add data"
      ". data";
    grid-template-rows: auto;
    align-content: start;
    column-gap: 82px;
    width: 100%;
    min-height: 561.5px;
    margin-top: -75px;
    padding: 0;
    position: relative;

    h2 {
      margin-top: 0;
      position: absolute;
      top: 112px;
      left: 0;
    }

    & > .uel-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .component-gps-filters {
      grid-area: filters;
      margin-top: 36px;
    }

    .balance-wrapper {
      grid-area: balance;
      display: flex;
      min-height: 108px;
      margin-top: 19px;

      .uel-loader {
        display: flex;
        transform: none;
      }

      .balance-cards-container {
        width: 100%;
      }
    }

    .expenses-card-wrapper {
      grid-area: data;
      display: flex;
      position: relative;
      min-height: 561.5px;

      &.tall {
        min-height: 561.5px;
      }

      .uel-loader {
        transform: translateY(-45px);
      }

      h3 {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 45px));
      }

      .component-expenses-card > h1 {
        margin-top: 0 !important;
        margin-bottom: 0;
      }
    }

    .button-copy {
      grid-area: add;
    }
  }
}
