@import "/src/theme/colors", "/src/theme/variables";

.mantine-Carousel-container {
  display: flex;
  height: 100%;
  .mantine-Carousel-slide {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .container-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: $color-gray-opcity;
    .container-img-logo {
      margin-top: 15px;
      #logo-uellbee {
        width: 100px;
      }
    }
    .container-titles {
      display: flex;
      flex: 2;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0px 50px 15px 50px;
      .title {
        height: 80px;
        justify-content: center;
        align-items: center;
        display: flex;
        span {
          text-align: center;
          font-family: $font-family-primary-bold;
          color: $color-white;
          font-size: 30px;
          line-height: 37px;
          padding-bottom: 45px;
        }
      }
      .title-lines {
        height: 80px;
        justify-content: center;
        align-items: center;
        padding: 0px 50px 0px 50px;
        display: flex;
        span {
          text-align: center;
          font-family: $font-family-primary-bold;
          color: $color-white;
          font-size: 30px;
          line-height: 37px;
        }
      }
      .subtitle {
        display: flex;
        height: 95px;
        align-items: center;
        justify-content: center;
        span {
          font-size: 14px;
          line-height: 16px;
          font-family: $font-family-secondary-regular;
          color: $color-white;
          text-align: center;
        }
      }
    }
    .container-buttons {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding-top: 80px;
      .btn-princi {
        display: flex;
        height: 65px;
        padding: 0px 47px 0px 47px;
      }
      .container-session {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        #txt-session {
          color: $color-white;
          font-family: $font-family-secondary-bold;
          font-size: 14px;
        }
      }
    }
  }
}
.mantine-Carousel-indicators {
  bottom: 35%;
  .mantine-Carousel-indicator {
    width: 10px;
    height: 10px;
  }
}

.mantine-Carousel-viewport{
  height: 100%;
}
.mantine-Carousel-root{
  height: 100%;
}

.extra-space {
  margin-bottom: 40px; 
  padding: 0 !important; // You can adjust this value to suit your needs.
}


@media only screen and (min-width: 376px) and (max-width: 414px) {
  .mantine-Carousel-indicators {
    padding-bottom: 15px;
  }


}