@import '/src/theme/index.scss';

.balance-cards-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:13px;

  .income-container,
  .expense-container
  {
    width: 50%;
  }

}