@import "/src/theme/index.scss", "/src/theme/variables", "/src/theme/mixins.scss";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--header-height);
  padding: 0 20px;

  .header-profile {
    display: none;
  }

  .logo {
    cursor: pointer;
    background: url("../../../../public/logos/logo.svg") no-repeat center;
    background-size: cover;
    width: 106px;
    height: 31px;
  }

  .menu-right {
    display: flex;
    gap:50px;

    .uel-button{
      display:none;
    }

    .uel-button.tablet-sign-in{
      min-width:150px;
    }

    .help-container {
      display: grid;
      place-items: center;
      align-content: center;
      gap: 2px;
      transform: translateY(2px);

      .text-help {
        font-family: $font-family-primary-bold;
        font-size: 8px;
        color: $color-primary;
        transform: translate(-1px);
      }
    }
  }
  @include tablet {
    padding: 0 40px;
    .logo {
        background: url("../../../../public/logos/logo-tablet.svg") no-repeat center;

        width: 164px;
        height: 47px;
    }
    .box:first-of-type {
      display: none;
    }
    .menu-right{
      &.tablet {
        display: none;
      }
      gap: 35px;
      .uel-button {
        min-height: 30px;
        display: flex;
        width: 150px;
        &.tertiary {
          width: 60px;
        }
      }
    }
    .header-profile {
      display: flex;
      flex-direction: row;
      position: relative;
      .header-profile-arrow {
        position: absolute;
        right: 0;
        align-self: center;
        margin-bottom: 10px;
      }
      .header-profile-img {
        background-color: $color-profile-image-bg;
        padding: 10px;
        border-radius: 100%;
        height: 60px;
        width: 60px;
        object-fit: contain;
      }
      .profile-data-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 120px;
        max-width: 200px;
        padding-left: 15px;
        justify-content: center;
        flex-wrap: wrap;
        .profile-username {
          font-family: $font-family-secondary-bold;
          font-size: 16px;
          line-height: 18px;
          color: $color-primary;
          margin-bottom: 5px;
        }
        .profile-email {
          font-family: $font-family-secondary-regular;
          font-size: 14px;
          line-height: 18px;
          color: $color-tertiary;
        }
      }
    }
  }
}
