@import "/src/theme/colors", "/src/theme/variables";

.text-input-component {
  margin-bottom: 20px;
  width: 100%;

  .mantine-TextInput-label {
    margin-left: 10px;
    margin-bottom: 10px;
    color: $color-tertiary;
    font-size: 15px;
    font-family: $font-family-secondary-medium;
    line-height: 14px;
  }
  .mantine-TextInput-wrapper {
    border-width: 0px;
  }
  .mantine-TextInput-input {
    border: 2px solid $color-gray-light;
    color: $color-tertiary;
    font-size: 14px;
    height: 50px;
    padding-left: 18px;
    border-radius: 7px;
    font-family: $font-family-secondary-medium;
  }
  .mantine-TextInput-input::placeholder {
    font-family: $font-family-secondary-medium;
    color: $color-tertiary;
    font-size: 14px;
  }
  .mantine-TextInput-invalid {
    border-color: $color-yellow;
  }
  .mantine-TextInput-invalid::placeholder {
    color: $color-tertiary;
    font-family: $font-family-secondary-medium;
  }

  .mantine-TextInput-input:focus:focus-within {
    border: 2px solid $color-gray-light;
    color: $color-tertiary;
  }
  .mantine-TextInput-error {
    color: $color-yellow;
    font-size: 10px;
    margin-left: 10px;
    font-family: $font-family-secondary-medium;
  }
  #icon-right {
    width: 28px;
    height: auto;
    margin-right: 20px;
    min-width: 28px;
    cursor: pointer;
  }

  @include tablet {
    .mantine-TextInput-label {
      font-size: 18px;
    }
    .mantine-TextInput-input {
      font-size: 14px;
    }
    .mantine-TextInput-error {
      font-size: 12px;
    }
  }
}
