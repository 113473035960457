@import '/src/theme/colors';

.uel-modal {
  .mantine-Modal-root {
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .mantine-Modal-close {
    svg {
      stroke: $color-primary;
      height: 50px;
      width: 50px;
    }
  }
  
  .mantine-Modal-modal {
    border-radius: 35px;
    padding: 30px 30px 60px 30px;
    overflow: scroll;
  }
  
  .mantine-Modal-overlay {
    background-color: $color-gray-dark;
  }
}
