.home-container-cb {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /*padding: 20px;*/
    color: #1e335c; 
    margin-bottom: 0;
}

.text-center {
    text-align: center;
}

.cb-description-carousel {
    flex: 1;
    align-items: center;
    width: 100%;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 20px;
}

.cb-description-carousel .mantine-carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mantine-Carousel-indicators{
    position: relative;
    padding-top: 10px;
}

.message-part-cb {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    max-width: 800px;
    position:relative;
}

.message-part-cb h2 {
    text-align: center;
    width: 100%;
}

.message-part-cb-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 50px;
    max-width: 500px;
    position:relative;
}

.message-part-cb-1 h2 {
    text-align: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 0px;
    padding-top: 50px;
}

.circle-sart-container::before { 
    content: "";
    display: flex;
    margin: 10% auto 10%;
    width: 90px; 
    height: 90px; 
    background: #1e335c;
    border-radius: 50%;
}

.button-container-cb {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.uel-button .energize-credit {
    font-size: 21px;
    text-align: center;
    line-height: 24px;
    padding: 12px 40px;
}

.custom-slide {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding-bottom: 20px;

    /*align-items: center;*/
    /*padding-bottom: 150px;*/

    p {
        font-size: inherit;
        margin-bottom: 20px;
        font-family: sans-serif; /* temporal fix; remove after checking Work-regular font font-weights */
    }
}

.custom-slide h2 {
    text-align: center;
    width: 100%;
}

.home-layout .home-layout-content .content .home-container-cb .h3-cb {
    text-align: center;    
}

.mt-3 {
    padding-top: 15px;
    padding-bottom: 80px;
}

.thumbs-up {
    max-width: 20%; /* Adjust width to the size of the container */
    height: auto; /* Keep the aspect ratio */
    padding-top: 3%;
}

.payments {
    max-width: 20%; /* Adjust width to the size of the container */
    height: auto; /* Keep the aspect ratio */
    padding-top: 1%;
}

.score {
    max-width: 20%; /* Adjust width to the size of the container */
    height: auto; /* Keep the aspect ratio */
    padding-top: 1%;
}

.benefits-list{
    justify-content: center;
    list-style-type: none;
    font-family: sans-serif;
}

.benefits-list li {
    margin: 2% 0;
}

.benefits-list li::before {
    content: "\2713";
    margin-right: 5px;
}

// .carousel-indicators {
//     position: relative;
//     top: 10px;  /* Adjust this as needed */
// }

/* iPhone friendly styles */
@media (max-width: 767px) {

    .message-part-cb, .message-part-cb-1 {
        max-width: 90%;
        padding: 1px;
    }
  
    // .custom-slide {
    //   /* padding-bottom: 50px; */
    // }
  
    .message-part-cb-1 h2 {
        padding-top: 20px;
    }
  
    .message-part-cb-1:before {
        // top: -10px; 
        width: 60px; 
        height: 60px; 
    }

    .cb-description-carousel {
        flex: 1;
        align-items: center;
        width: 100%;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
        // z-index: -1;
    }

    .button-container-cb {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .thumbs-up {
        max-width: 45%; /* Adjust width to the size of the container */
        height: auto; /* Keep the aspect ratio */
        padding-top: 5px;
    }
    
    .payments {
        max-width: 45%; /* Adjust width to the size of the container */
        height: auto; /* Keep the aspect ratio */
        padding-top: 5px;
    }
    
    .score {
        max-width: 45%; /* Adjust width to the size of the container */
        height: auto; /* Keep the aspect ratio */
        padding-top: 5px;
    }
}

@media only screen and (min-width: 279px) and (max-width: 415px) {
    
    .home-container-cb {
        min-height: unset;
    }

    .message-part-cb-1:before {
        top: -10%;
        width: 40px; 
        height: 40px;
    }

    .mt-3 {
        padding-top: 15px;
        padding-bottom: 80px;
    }

    .cb-description-carousel {
        flex: 1;
        align-items: center;
        width: 100%;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 20px;
    }

    .button-container-cb {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        padding-top: 25px;
    }

    .thumbs-up {
        max-width: 45%; /* Adjust width to the size of the container */
        height: auto; /* Keep the aspect ratio */
        padding-top: 5px;
    }
    
    .payments {
        max-width: 45%; /* Adjust width to the size of the container */
        height: auto; /* Keep the aspect ratio */
        padding-top: 5px;
    }
    
    .score {
        max-width: 45%; /* Adjust width to the size of the container */
        height: auto; /* Keep the aspect ratio */
        padding-top: 5px;
    }
}