@import '/src/theme/colors', '/src/theme/variables', '/src/theme/index.scss';

.uel-checkbox-component{
  display: flex;
  flex-direction: row;
  align-items: center;
  .mantine-Checkbox-root {
    .mantine-Checkbox-inner {
      .mantine-Checkbox-input:checked {
        background-color: $color-primary;
        border: none;
      }
    }
  }
  .uel-label {
    margin-left: 15px;
    font-size: 12px;
    font-family: $font-family-secondary-medium ;
    color: $color-tertiary;
    .quickfix {
      span {
        font-size: 12px !important;
        font-family: $font-family-secondary-medium;
        font-size: 12px;
        line-height: 14px;
        color: $color-tertiary;
      }
      .link {
        font-size: 12px !important;
        text-decoration: underline;
        color: $color-primary;
      }
    }
  }
}