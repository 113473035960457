@import "/src/theme/index.scss";

.principal-add-new-bank-account-container {
  display: flex;
  flex-direction: column;
  height: 128px;
  margin-top: 20px;

  .uel-loader {
    margin: auto;
    transform: translateY(-2vh) !important;
  }

  .title-text {
    font-size: 15px;
    line-height: 18px;
    font-family: $font-family-secondary-semibold;
    color: $color-secondary;
  }

  .button-and-accounts-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;

    .gps-add-button-container {
      margin-top: 7px;
    }

    .mantine-Carousel-container {
      height: 80px;
      margin-top: 10px;
      padding-right:10px;

      .card-bank-container {
        margin-left: 8px;
      }
    }
  }

  @include tablet{
    .uel-loader {
      margin: auto;
      transform: none !important;
    }
  }
}
