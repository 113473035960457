@import "colors.scss", "variables", "fontface", "normalizer";

:root {
  --submitWidthTablet: 342px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    width: 0 !important;
  }

}

html {
  height: 100vh;
}

body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

a,
button {
  appearance: none;
  background: none;
  border: 0;
  cursor:pointer;
  text-decoration: none;
}

a:hover{
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

ul{
  list-style-type: none;
}
