@import "/src/theme/index.scss";

.mygps-container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 267px);
  padding: 30px 0 $navbar-height;

  &.ios {
    min-height: calc(100vh - $navbar-height);
  }

  .uel-loader {
    margin: auto;
    transform: translateY(-3vh);
  }

  h1 {
    font-size: 30px;
    line-height: 39px;
    color: $color-secondary-alt;
    text-align: center;
    margin: 20px 0 30px 0;
  }

  .uel-gps-button {
    margin-bottom: 24px;
  }

  @include tablet {
    display: grid;
    min-height: 0;
    padding-top: 70px;
    padding-bottom: 0;
    grid-template-columns: 1fr 55%;
    grid-template-rows: 120px 1fr;
    column-gap: 25px;

    .uel-loader {
      margin: auto;
      transform: none;
    }

    .credit-score-card {
      grid-row: 1 / 3;
      grid-column: 2 / 3;
    }

    .principal-add-new-bank-account-container {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }

    .button-and-accounts-container {
      margin: 0;
    }
  }
}
