@import "/src/theme/colors", "/src/theme/variables";

.component-uel-web-footer {
  width: 100vw;
  padding-top: 35px;
  background: $color-primary;
  margin-top: auto;

  *{
    color:$color-white;
  }

  a:hover{
    color:$color-white;
  }

  .footer-section {
    display: flex;
    padding: 0 45px;

    &.center {
      justify-content: space-between;
      margin-top: 33px;

      .address-social {
        width: 100%;
        justify-content: space-between;

        .address {
          width: 272px;
          font-family: $font-family-primary-regular;
          font-size: 16px;
        }
      }
      .legal-social-labels {
        display: flex;
        flex-direction: column;

        .legal-social {
          display: flex;
          flex-direction: column;
          gap: 0;

          .legal h3 {
            font-family: $font-family-primary-semibold;
            font-size: 16px;
          }

          .legal ul {
            display: flex;
            flex-direction: column;
            margin: 5px 0 0;

            li a {
              font-family: $font-family-primary-regular;
              font-size: 16px;

            }
          }

          .social {
            display: flex;
            gap:6px;
            margin:10px 0 0;

            .social-link {
              display: grid;
              place-items: center;
              width: 21px;
              height: 21px;
              position:relative;
              // background:$color-white;
              border-radius: 5px;
              overflow:hidden;

              .back-layer{
                display:grid;
                place-items: center;
                width:100%;
                height:100%;
              }

              img {
                object-fit: cover;
              }

              &:first-of-type{
                .back-layer{
                  background:url("../../../../public/icons/whatsapp-back.svg") no-repeat center;
                  background-size: cover;

                  img{
                    width:60%;
                    height:60%;
                  }
                }
              }

              &:nth-of-type(2){
                .back-layer{
                  background:url("../../../../public/icons/instagram-back.svg") no-repeat center;
                  background-size: cover;

                  img{
                    width:55%;
                    height:55%;
                  }
                }
              }
            }
          }
        }

        .labels {
          display: flex;
          align-items: center;
          width: 212px;
          gap: 11px;
          margin-top:39px;
        }
      }
    }

    &.bottom {
      justify-content: center;
      margin-top: 72px;
      padding: 20px 45px;
      background: $color-blue-light;

      .copyright{
        font-family: $font-family-primary-regular;
        font-size:12px;
      }
    }

    .footer-element {
      display: flex;
    }
  }
}
