@import "/src/theme/index.scss", "/src/theme/mixins.scss";

.home-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  overflow-x: hidden;

  &.custom-bg {
    background-image: url("../../../../public/images/background_home.svg");
  }
  .home-layout-content {
    height: calc(100% - $navbar-height);
    .content {
      height: calc(100% - var(--header-height) - 66px);
      padding: 25px 30px 0;

      & > h1 {
        font-size: 30px;
        line-height: 39px;
        color: $color-secondary-alt;
        text-align: center;
        margin-top: 45px;

        &.custom-margin-bottom {
          margin-top: 0;
        }
      }
    }

    h2 {
      color: $color-secondary;
      font-size: 20px;
      line-height: 39px;
      text-align: center;
    }
    .header-label {
      display: none;
      color: $color-secondary;
      font-family: $font-family-secondary-regular;
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    .backbutton-container {
      margin: 30px 0 0px 30px;
    }
  }
  .home-layout-navbar {
    background-color: transparent;
  }

  .component-uel-web-footer {
    display: none;
    margin-top: auto;
  }

  @include tablet {
    .home-layout-content {
      .content {
        padding: 25px 70px 150px;
        height: auto;
        margin-left: auto;
        margin-right: auto;

        h1 {
          margin-top: 67px;
          margin-bottom: 6px;
          text-align: left;
        }

        h2 {
          text-align: left;
        }
      }

      .header-label {
        display: block;
      }
      .backbutton-container {
        margin: 30px 0 0px 40px;
      }
    }

    .component-uel-web-footer {
      display: block;
    }
  }
}
