@import "/src/theme/index.scss", "/src/theme/colors", "/src/theme/variables";

.full-screen {
  .mantine-Modal-inner {
    padding: 0;
  }

  .mantine-Modal-modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .mantine-Modal-body {
    display: grid;
    flex-direction: column;
    place-items: center;

    img {
      width: 84px;
      height: 84px;
      margin-top: 150px;
    }

    .help-title {
      font-size: 30px;
      color: $color-primary;
      font-family: $font-family-primary-bold;
      margin-top: 56px;
    }

    .description-section {
      text-align: center;
      margin-top: 45px;
      color: $color-tertiary;

      .help-description {
        font-family: $font-family-secondary-regular;
        font-size: 15px;
        line-height: 18px;
      }

      .help-number {
        @extend .help-description;
        font-family: $font-family-secondary-bold;
        margin-top: 20px;
      }
    }

    .help-button {
      margin-top: 70px;
      font-family: $font-family-secondary-semibold;
      font-size: 12px;
      line-height: 14px;
      color: $color-primary;
    }
  }

  @include tablet {
    .help-title {
      font-size: 42px !important;
    }
    .description-section {
      margin-top: 25px !important;
      width: 400px;
    }
    .help-description,
    .help-number,
    .help-button {
      font-size: 21px !important;
    }
  }
}
