@import '/src/theme/colors', '/src/theme/mixins.scss';

/* Font families */
$font-family-primary-regular: 'Jakarta-regular';
$font-family-primary-extrabold: 'Jakarta-extrabold';
$font-family-primary-bold: 'Jakarta-bold';
$font-family-primary-semibold: 'Jakarta-semibold';
$font-family-secondary-bold: 'Work-bold';
$font-family-secondary-semibold: 'Work-semibold';
$font-family-secondary-medium: 'Work-medium';
$font-family-secondary-regular: 'Work-regular';

/* Font weights */
$extrabold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$regular: 400;

/* Header */
// $header-height: 53px;

/* Nav bar*/
$navbar-height: 66px;
$navbar-height-ios: 96px;
/* Shadows */
$shadow-around: 0px 0px 12px 2px $color-gray-dark-shadow;


/*
 * Sass variables are compiled and therefore can not be updated with media queries.
 * If we want the ability to update the variable with media queries, it needs to be defined
 * as a CSS custom property
*/

:root{
  --header-height: 75px;
  
}

@include tablet {
  :root{
    --header-height: 110px;
  }
}