@import "/src/theme/index.scss";

.card-container {
  display: flex;
  flex-direction: row;
  background-color: $color-white;
  padding: 35px 30px;
  border-radius: 35px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: $shadow-around;

  .img-wrapper {
    display: grid;
    place-items: center;
    min-width: 50px;

    img {
      min-width: 35px;
      object-fit: cover;

      &.small {
        min-width: 30px;
      }

      &.big {
        min-width: 50px;
      }
    }
  }

  .comingSoon-text {
    font-family: $font-family-primary-bold;
    font-size: 15px;
    font-style: italic;
    color: $color-gray-dark;
  }

  .text-card {
    color: $color-secondary;
    align-self: center;
    font-size: 20px;
    font-family: $font-family-primary-bold;
    line-height: 25px;
  }
}
