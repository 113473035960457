@import '/src/theme/index.scss';

.selecttimeframe-container {
  padding-top: 52px;
  h1 {
    font-size: 30px;
    line-height: 39px;
    color: $color-secondary-alt;
    text-align: center;
    margin: 20px 0 80px 0;
  }
  .uel-gps-button {
    margin-bottom: 24px;
  }
}
