@import "/src/theme/index.scss";

.container-navbar {
  height: $navbar-height;
  border-radius: 25px 25px 0px 0px;
  display: flex;
  align-items: center;
  background-color: $color-white;
  box-shadow: $shadow-around;
  position: fixed;
  bottom: 0;
  width: 100vw;

  &.ios {
    height: $navbar-height-ios;
  }

  .mantine-Menu-item {
    background-color: transparent;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    min-width: 60px;

    &:hover {
      .title-text {
        cursor: pointer;
        color: $color-primary !important;
      }

      .iconWrapper svg path {
        fill: $color-primary !important;
      }
    }

    .mantine-Menu-itemIcon {
      justify-content: center;
      margin-right: 0;

      .iconWrapper {
        svg {
          pointer-events: none;

          path {
            fill: $color-secondary;
            stroke: $color-white;
            transition: fill 0.2s ease-out;
            stroke-width: 0;
          }

          width: 25px;
          height: 25px;
        }

        &.active path {
          fill: $color-primary;
        }
      }
    }

    .mantine-Menu-itemLabel {
      flex: 0;
    }

    .mantine-Menu-label {
      padding: 0;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      color: $color-secondary;
      font-family: $font-family-primary-bold;

      &.active {
        color: $color-primary;
      }
    }
  }

  @include tablet {
    display: none;
  }
}
