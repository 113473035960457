@import "/src/theme/index.scss", "/src/theme/mixins.scss";

.component-uel-balance-card {
  display:flex;
  flex-direction: column;
  .label-card {
    font-family: $font-family-secondary-semibold;
    font-size: 10px;
    line-height: 18px;
    color: $color-secondary;
    margin-left: 3px;
  }

  .figures-container {
    display: grid;
    place-items: center;
    background-color: $color-white;
    box-shadow: 0px 0px 15px 1px $color-gray-light;
    min-height: 40px;
    border-radius: 10px;
    margin-top: 5px;

    .figures-text {
      color: $color-primary;
      font-family: $font-family-secondary-semibold;
      font-size: 16px;
      line-height: 18px;
      margin: 10px;

      &.negative {
        color: $color-yellow;
      }
    }
  }

  @include tablet {
    .label-card {
      font-size: 12px;
    }
  }
}
