@import "../../../theme/colors", "../../../theme/fontface";

.expenses-card-container {
  h3 {
    font-family: $font-family-secondary-semibold;
    color:$color-secondary;
    text-align: center;
  }
  .component-expenses-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:20px 22px;
    border-radius: 10px;
    cursor:default;
  
    h1 {
      font-family: $font-family-secondary-semibold;
      font-size: 10px;
      width: 100%;
      margin-top:0;
      margin-bottom:0;
      text-align: left;
      color: $color-secondary;
      line-height:2.2;
      text-indent:4px;
    }
  
    .categories {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap:15px;
      width: 100%;
      margin-top: -22px;
    }
  
    .categories .component-gps-category {
      width:calc(50% - 10px);
      
      .component-gps-category-label{
        cursor:pointer;
      }
    }
  }
}
