@import "/src/theme/colors", "/src/theme/variables";

.register-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;

  &.fixed-height {
    height: 100%;
  }

  .register-layout-content {
    padding: 36px 30px 0;
    .content {
      display: flex;
      justify-content: center;

      .principal-address-container {
        .form-adress-container {
          margin-top: 30px;
        }
      }
    }
  }

  .component-uel-web-footer {
    display: none;
    margin-top: auto;
  }

  @include tablet {
    .text-input-component {
      margin-left: auto;
      margin-right: auto;
    }
    .form-title,
    .register-text {
      color: $color-gray-dark;
    }
    .title-text {
      font-size: 42px !important;
      line-height: 52px;
    }
    .subtitle-text {
      font-size: 21px !important;
      line-height: 24px !important;
    }
    .subtitle-container {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    .register-layout-content {
      padding: 49px 65px 45px;

      .content {
        .img-container {
          width: 206px;
          height: 195px;
          margin: 0 auto 53px;
        }
        .subtitle-container {
          margin: 24px auto 0;
        }

        .register-container {
          margin-top: 89px;
        }
        .form-container {
          max-width: 612px;
          margin: 63px auto 0;

          .checkbox-container {
            width: var(--submitWidthTablet);
            margin: 39px auto 0;
          }
        }
      }
    }

    .component-uel-web-footer {
      display: block;
    }
  }
}
